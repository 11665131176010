module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-KDRSN5J","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apartmány Humenné","short_name":"Apartmány Humenné","start_url":"/","background_color":"#57595D","theme_color":"#57595D","display":"minimal-ui","icon":"src/images/favicon.png","icons":[{"src":"/favicons/apartmany-humenne-16.png","sizes":"16x16","type":"image/png"},{"src":"/favicons/apartmany-humenne-32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/apartmany-humenne-64.png","sizes":"64x64","type":"image/png"},{"src":"/favicons/apartmany-humenne-128.png","sizes":"128x128","type":"image/png"},{"src":"/favicons/apartmany-humenne-152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/apartmany-humenne-167.png","sizes":"167x167","type":"image/png"},{"src":"/favicons/apartmany-humenne-180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/apartmany-humenne-192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/apartmany-humenne-196.png","sizes":"196x196","type":"image/png"},{"src":"/favicons/apartmany-humenne-310.png","sizes":"310x310","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7b5d17cab83677f6f993aad6555c339b"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
